import styles from "./ItemDetails.module.scss";
// import WatchCondition1 from '../../../../assets/watchCondition1.jpg';
import {
    ChangeEvent,
    ReactNode,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
    useMemo,
} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    InputAdornment,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { WatchInfoBuyDetails } from "../../../../models/WatchInfoBuyDetails";
import { ReactComponent as GavelIcon } from "../../../../assets/gavel.svg";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BidHistoryTree from "./BidHistoryTree";
// import {ReactComponent as SellerDetailsGavelIcon} from '../../../../assets/sellerDetailsGavel.svg';
// import {ReactComponent as CartIcon} from '../../../../assets/shoppingCart.svg';
import { useMediaQuery } from "react-responsive";
import {
    LightGalleryLicense,
    MobileScreenWidth,
} from "../../../../../constants";
import ConditionTooltip from "../../../conditionTooltip/ConditionTooltip";
import Parser from "html-react-parser";
import { BidDialog } from "../../../dialogs/bid/BidDialog";
import EndTimer from "../../../endTimer/EndTimer";
import { AuthContext } from "../../../../providers/AuthProvider";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { getDisplayDate } from "../../../../../utils/date/dateUtils";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { InitDetail } from "lightgallery/lg-events";
import { LightGallery as LightGalleryPlugin } from "lightgallery/lightgallery";
import { watchUnwatch } from "../../../../services/auction-service";
import { SnackContext } from "../../../../providers/SnackProvider";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumericFormatCustom = forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                prefix="£"
            />
        );
    },
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        sx: { minHeight: "36px", height: "36px" },
    };
}

export interface ItemDetailsProps {
    watchDetails: WatchInfoBuyDetails;
    isPreview?: boolean;
    onCountdownFinished: () => void;
    updateFavCounts: (count: number, isFavourite: boolean) => void;
    scrolling?: boolean;
}

const ItemDetails = ({
    watchDetails,
    isPreview,
    onCountdownFinished,
    updateFavCounts,
    scrolling,
}: ItemDetailsProps) => {
    const isDesktop = useMediaQuery({
        query: `(min-device-width: ${MobileScreenWidth}px)`,
    });

    const showSnackbar = useContext(SnackContext);
    const { user, login } = useContext(AuthContext);
    const [selectedTab, setSelectedTab] = useState(0);
    const [showBidHistory, setShowBidHistory] = useState(false);
    const [showCondition, setShowCondition] = useState(false);
    const [minBidAmountString, setMinBidAmountString] = useState("");
    const [minBidAmount, setMinBidAmount] = useState(0);
    const [bidAmount, setBidAmount] = useState<number>();
    const [bidError, setBidError] = useState<ReactNode>(
        `Bid is less than the minimum bid of £${watchDetails.minBid}`,
    );
    const [showBidConfirmDialog, setShowBidConfirmDialog] = useState(false);
    const [bidDialogWatchDetails, setBidDialogWatchDetails] =
        useState(watchDetails);
    const [bidDialogBidAmount, setBidDialogBidAmount] = useState(0);
    const [isHighestBidder, setIsHighestBidder] = useState(false);
    const [isOutBid, setIsOutBid] = useState(false);
    const lightGalleryRef = useRef<LightGalleryPlugin>();
    const navigate = useNavigate();

    const onInit = useCallback((detail: InitDetail) => {
        lightGalleryRef.current = detail.instance;
    }, []);

    const handleGalleryShow = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            if (lightGalleryRef.current) {
                lightGalleryRef.current.openGallery();
            }
        },
        [],
    );

    const handleSetupStripeClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            navigate("/member");
        },
        [navigate],
    );

    const handleLoginClick = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            login(!isDesktop);
        },
        [isDesktop, login],
    );

    const checkUser = useCallback(() => {
        if (!user) {
            return (
                <span className={styles.bidErrorLabel}>
                    Please{" "}
                    <a
                        className={styles.bidErrorLink}
                        href="/"
                        onClick={handleLoginClick}
                    >
                        log in
                    </a>{" "}
                    to place a bid
                </span>
            );
        } else if (!user.email_verified) {
            return "Please confirm your email address by checking your registered email account";
        } else if (!user.payment_method_attached) {
            return (
                <span className={styles.bidErrorLabel}>
                    Please click{" "}
                    <a
                        className={styles.bidErrorLink}
                        href="/"
                        onClick={handleSetupStripeClick}
                    >
                        here
                    </a>{" "}
                    to setup your credit card details before placing a bid
                </span>
            );
        }

        return "";
    }, [handleLoginClick, handleSetupStripeClick, user]);

    useEffect(() => {
        setMinBidAmountString(watchDetails.minBid.toLocaleString());
        setMinBidAmount(watchDetails.minBid);
        setBidAmount(undefined);
        setBidError(checkUser());
    }, [checkUser, watchDetails.minBid]);

    const onBidClick = useCallback(() => {
        // Generate a copy of the watch details in case they get updated
        const watchDetailsCopy: WatchInfoBuyDetails = JSON.parse(
            JSON.stringify(watchDetails),
        );
        setBidDialogWatchDetails(watchDetailsCopy);
        setBidDialogBidAmount(bidAmount ?? minBidAmount);
        setShowBidConfirmDialog(true);
    }, [bidAmount, minBidAmount, watchDetails]);

    const onCloseBidConfirmDialog = useCallback(() => {
        setShowBidConfirmDialog(false);
    }, []);

    const onTabChanged = useCallback(
        (event: React.SyntheticEvent, newValue: number) => {
            setSelectedTab(newValue);
        },
        [],
    );

    const onClearBid = useCallback(() => {
        setBidAmount(undefined);
    }, []);

    const onBidAmountChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const userError = checkUser();

            if (userError) {
                setBidAmount(undefined);
                setBidError(userError);
                return;
            }

            if (!event.target.value) {
                setBidAmount(undefined);
                setBidError("");
                return;
            }

            const newValue = Number(event.target.value);

            if (isNaN(newValue)) {
                setBidError(`Invalid value entered ${event.target.value}`);
                setBidAmount(0);
                return;
            }

            if (!Number.isInteger(newValue)) {
                setBidError("The bid value must be a whole number");
                setBidAmount(newValue);
            } else if (newValue < minBidAmount) {
                setBidError(
                    `Bid is less than the minimum bid of £${minBidAmountString}`,
                );
                setBidAmount(newValue);
            } else {
                setBidAmount(newValue);
                setBidError("");
            }
        },
        [checkUser, minBidAmount, minBidAmountString],
    );

    const onFavouritesToggle = useCallback(
        async (e: React.MouseEvent<HTMLElement>) => {
            try {
                await watchUnwatch(watchDetails.id, {
                    watching: !watchDetails.isFavourite,
                });
                const prevCount = watchDetails.savedAsFavouriteCount;
                const count = !watchDetails.isFavourite
                    ? prevCount + 1
                    : prevCount - 1;
                updateFavCounts(count, !watchDetails.isFavourite);
            } catch (error: any) {
                showSnackbar(error.message, { alertSeverity: "error" });
            }
        },
        [showSnackbar, updateFavCounts, watchDetails],
    );

    useEffect(() => {
        const isHighest =
            !!watchDetails.currentBidUser &&
            watchDetails.currentBidUser === user?.public_display_name;
        const outbid = isHighest
            ? false
            : !!watchDetails.bidHistory.find(
                  (x) => !!x.user && x.user === user?.public_display_name,
              );

        setIsHighestBidder(isHighest);
        setIsOutBid(outbid);
    }, [
        user?.public_display_name,
        watchDetails.bidHistory,
        watchDetails.currentBidUser,
    ]);

    const isTenantAdmin = useMemo(
        () => user?.role.includes("Tenant Admin"),
        [user],
    );

    const BiddingLabel = () => {
        return (
            <Stack direction="row" className={styles.mainInfoContainer}>
                <Stack />
                {watchDetails.status === "Live" && (
                    <Stack
                        direction="column"
                        className={styles.bidDetailsContainer}
                    >
                        {isHighestBidder && (
                            <Stack
                                direction="row"
                                className={styles.highestBidderContainer}
                            >
                                <Typography
                                    className={styles.highestBidderLabel}
                                    noWrap
                                >
                                    YOU'RE THE HIGHEST BIDDER
                                </Typography>
                            </Stack>
                        )}

                        {isOutBid && (
                            <Stack
                                direction="row"
                                className={styles.outbidContainer}
                            >
                                <Typography
                                    className={styles.outbidLabel}
                                    noWrap
                                >
                                    YOU'VE BEEN OUTBID
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                )}

                {watchDetails.status === "EndedSold" && (
                    <Stack
                        direction="column"
                        className={styles.bidDetailsContainer}
                    >
                        {isHighestBidder && (
                            <Stack
                                direction="row"
                                className={styles.highestBidderContainer}
                            >
                                <Typography
                                    className={styles.highestBidderLabel}
                                    noWrap
                                >
                                    YOU'RE THE HIGHEST BIDDER
                                </Typography>
                            </Stack>
                        )}

                        {isOutBid && (
                            <Stack
                                direction="row"
                                className={styles.outbidContainer}
                            >
                                <Typography
                                    className={styles.outbidLabel}
                                    noWrap
                                >
                                    YOU'VE BEEN OUTBID
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                )}
            </Stack>
        );
    };

    return (
        <Stack direction="column" className={styles.root}>
            {!isDesktop &&
                watchDetails.status === "Live" &&
                isHighestBidder && (
                    <Stack
                        direction="row"
                        className={styles.highestBidderContainerMobile}
                    >
                        <div className={styles.highestBidderContainer}>
                            <Typography
                                className={styles.highestBidderLabel}
                                noWrap
                            >
                                YOU'RE THE HIGHEST BIDDER
                            </Typography>
                        </div>
                    </Stack>
                )}

            {!isDesktop && watchDetails.status === "Live" && isOutBid && (
                <Stack direction="row" className={styles.outbidContainerMobile}>
                    <div className={styles.outbidContainer}>
                        <Typography className={styles.outbidLabel} noWrap>
                            YOU'VE BEEN OUTBID
                        </Typography>
                    </div>
                </Stack>
            )}

            {!isDesktop &&
                watchDetails.status === "EndedSold" &&
                isHighestBidder && (
                    <Stack
                        direction="row"
                        className={styles.highestBidderContainerMobile}
                    >
                        <div className={styles.highestBidderContainer}>
                            <Typography
                                className={styles.highestBidderLabel}
                                noWrap
                            >
                                YOU'RE THE HIGHEST BIDDER
                            </Typography>
                        </div>
                    </Stack>
                )}

            {isDesktop ? (
                <Stack direction="row" className={styles.mainInfoContainer}>
                    <Stack direction="column" className={styles.nameContainer}>
                        <Typography className={styles.brandLabel}>
                            {watchDetails.brand}
                        </Typography>
                        <Typography className={styles.modelLabel}>
                            {watchDetails.model}
                        </Typography>
                        <Typography className={styles.yearLabel}>
                            {watchDetails.year}
                        </Typography>
                    </Stack>

                    {watchDetails.status === "Live" && (
                        <Stack
                            direction="column"
                            className={styles.bidDetailsContainer}
                        >
                            {isHighestBidder && (
                                <Stack
                                    direction="row"
                                    className={styles.highestBidderContainer}
                                >
                                    <Typography
                                        className={styles.highestBidderLabel}
                                        noWrap
                                    >
                                        YOU'RE THE HIGHEST BIDDER
                                    </Typography>
                                </Stack>
                            )}

                            {isOutBid && (
                                <Stack
                                    direction="row"
                                    className={styles.outbidContainer}
                                >
                                    <Typography
                                        className={styles.outbidLabel}
                                        noWrap
                                    >
                                        YOU'VE BEEN OUTBID
                                    </Typography>
                                </Stack>
                            )}

                            <Stack
                                direction="row"
                                className={styles.bidDetailsLowerContainer}
                            >
                                <Stack
                                    direction="column"
                                    className={styles.currentBidContainer}
                                >
                                    <Typography
                                        className={styles.currentBidLabel}
                                        noWrap
                                    >
                                        CURRENT BID
                                    </Typography>
                                    <Typography
                                        className={
                                            watchDetails.currentBid
                                                ? styles.currentBidValue
                                                : styles.currentBidNoValue
                                        }
                                        noWrap
                                    >
                                        {watchDetails.currentBid
                                            ? `£${watchDetails.currentBid.toLocaleString()}`
                                            : "No bids"}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction="row"
                                    className={styles.spacerContainer}
                                >
                                    <div className={styles.spacer} />
                                </Stack>

                                <Stack
                                    direction="row"
                                    className={styles.favouritesContainer}
                                >
                                    <Typography
                                        className={styles.bidCountValue}
                                    >
                                        {watchDetails.bids}
                                    </Typography>
                                    <GavelIcon className={styles.gavelIcon} />
                                    <Typography className={styles.gavelBase}>
                                        _
                                    </Typography>
                                    <Typography
                                        className={styles.favouritesCountValue}
                                    >
                                        {watchDetails.savedAsFavouriteCount}
                                    </Typography>
                                    <IconButton
                                        aria-label={
                                            watchDetails.isFavourite
                                                ? "Remove from favourites"
                                                : "Add to favourites"
                                        }
                                        disabled={!user || isTenantAdmin}
                                        onClick={onFavouritesToggle}
                                    >
                                        {watchDetails.isFavourite ? (
                                            <FavoriteIcon
                                                className={styles.favouriteIcon}
                                            />
                                        ) : (
                                            <FavoriteBorderIcon
                                                className={styles.favouriteIcon}
                                            />
                                        )}
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}

                    {watchDetails.status === "EndedSold" && (
                        <Stack
                            direction="column"
                            className={styles.bidDetailsContainer}
                        >
                            {isHighestBidder && (
                                <Stack
                                    direction="row"
                                    className={styles.highestBidderContainer}
                                >
                                    <Typography
                                        className={styles.highestBidderLabel}
                                        noWrap
                                    >
                                        YOU'RE THE HIGHEST BIDDER
                                    </Typography>
                                </Stack>
                            )}

                            {isOutBid && (
                                <Stack
                                    direction="row"
                                    className={styles.outbidContainer}
                                >
                                    <Typography
                                        className={styles.outbidLabel}
                                        noWrap
                                    >
                                        YOU'VE BEEN OUTBID
                                    </Typography>
                                </Stack>
                            )}

                            <Stack
                                direction="row"
                                className={styles.bidDetailsLowerContainer}
                            >
                                <Stack
                                    direction="column"
                                    className={styles.currentBidContainer}
                                >
                                    <Typography
                                        className={styles.currentBidLabel}
                                        noWrap
                                    >
                                        WINNING BID
                                    </Typography>
                                    <Typography
                                        className={
                                            watchDetails.currentBid
                                                ? styles.currentBidValue
                                                : styles.currentBidNoValue
                                        }
                                        noWrap
                                    >
                                        {watchDetails.currentBid
                                            ? `£${watchDetails.currentBid.toLocaleString()}`
                                            : "No bids"}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction="row"
                                    className={styles.spacerContainer}
                                >
                                    <div className={styles.spacer} />
                                </Stack>

                                <Stack
                                    direction="row"
                                    className={styles.favouritesContainer}
                                >
                                    <Typography
                                        className={styles.bidCountValue}
                                    >
                                        {watchDetails.bids}
                                    </Typography>
                                    <GavelIcon className={styles.gavelIcon} />
                                    <Typography className={styles.gavelBase}>
                                        _
                                    </Typography>
                                    <Typography
                                        className={styles.favouritesCountValue}
                                    >
                                        {watchDetails.savedAsFavouriteCount}
                                    </Typography>
                                    <IconButton
                                        aria-label={
                                            watchDetails.isFavourite
                                                ? "Remove from favourites"
                                                : "Add to favourites"
                                        }
                                        disabled={!user || isTenantAdmin}
                                        onClick={onFavouritesToggle}
                                    >
                                        {watchDetails.isFavourite ? (
                                            <FavoriteIcon
                                                className={styles.favouriteIcon}
                                            />
                                        ) : (
                                            <FavoriteBorderIcon
                                                className={styles.favouriteIcon}
                                            />
                                        )}
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            ) : (
                <Stack direction="row" className={styles.mainInfoContainer}>
                    <Stack direction="column" className={styles.nameContainer}>
                        <Typography className={styles.brandLabelMobile}>
                            {watchDetails.brand}
                        </Typography>
                        <Typography className={styles.modelLabelMobile}>
                            {watchDetails.model}
                        </Typography>
                        <Typography className={styles.yearLabelMobile}>
                            {watchDetails.year}
                        </Typography>
                    </Stack>

                    {watchDetails.status === "Live" && (
                        <Stack
                            direction="column"
                            className={styles.bidDetailsContainer}
                        >
                            <Stack direction="column">
                                <Stack
                                    direction="column"
                                    className={styles.currentBidContainerMobile}
                                >
                                    <Typography
                                        className={styles.currentBidLabel}
                                        noWrap
                                    >
                                        CURRENT BID
                                    </Typography>
                                    <Typography
                                        className={
                                            watchDetails.currentBid
                                                ? styles.currentBidValue
                                                : styles.currentBidNoValue
                                        }
                                        noWrap
                                    >
                                        {watchDetails.currentBid
                                            ? `£${watchDetails.currentBid.toLocaleString()}`
                                            : ""}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction="row"
                                    className={styles.spacerContainerMobile}
                                >
                                    <div className={styles.spacer} />
                                </Stack>

                                <Stack
                                    direction="row"
                                    className={styles.favouritesContainerMobile}
                                >
                                    <Typography
                                        className={styles.bidCountValue}
                                    >
                                        {watchDetails.bids}
                                    </Typography>
                                    <GavelIcon className={styles.gavelIcon} />
                                    <Typography className={styles.gavelBase}>
                                        _
                                    </Typography>
                                    <Typography
                                        className={styles.favouritesCountValue}
                                    >
                                        {watchDetails.savedAsFavouriteCount}
                                    </Typography>
                                    <IconButton
                                        aria-label={
                                            watchDetails.isFavourite
                                                ? "Remove from favourites"
                                                : "Add to favourites"
                                        }
                                        disabled={!user || isTenantAdmin}
                                        onClick={onFavouritesToggle}
                                    >
                                        {watchDetails.isFavourite ? (
                                            <FavoriteIcon
                                                className={styles.favouriteIcon}
                                            />
                                        ) : (
                                            <FavoriteBorderIcon
                                                className={styles.favouriteIcon}
                                            />
                                        )}
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}

                    {watchDetails.status === "EndedSold" && (
                        <Stack
                            direction="column"
                            className={styles.bidDetailsContainer}
                        >
                            <Stack direction="column">
                                <Stack
                                    direction="column"
                                    className={styles.currentBidContainerMobile}
                                >
                                    <Typography
                                        className={styles.currentBidLabel}
                                        noWrap
                                    >
                                        WINNING BID
                                    </Typography>
                                    <Typography
                                        className={
                                            watchDetails.currentBid
                                                ? styles.currentBidValue
                                                : styles.currentBidNoValue
                                        }
                                        noWrap
                                    >
                                        {watchDetails.currentBid
                                            ? `£${watchDetails.currentBid.toLocaleString()}`
                                            : ""}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction="row"
                                    className={styles.spacerContainerMobile}
                                >
                                    <div className={styles.spacer} />
                                </Stack>

                                <Stack
                                    direction="row"
                                    className={styles.favouritesContainerMobile}
                                >
                                    <Typography
                                        className={styles.bidCountValue}
                                    >
                                        {watchDetails.bids}
                                    </Typography>
                                    <GavelIcon className={styles.gavelIcon} />
                                    <Typography className={styles.gavelBase}>
                                        _
                                    </Typography>
                                    <Typography
                                        className={styles.favouritesCountValue}
                                    >
                                        {watchDetails.savedAsFavouriteCount}
                                    </Typography>
                                    <IconButton
                                        aria-label={
                                            watchDetails.isFavourite
                                                ? "Remove from favourites"
                                                : "Add to favourites"
                                        }
                                        disabled={!user || isTenantAdmin}
                                        onClick={onFavouritesToggle}
                                    >
                                        {watchDetails.isFavourite ? (
                                            <FavoriteIcon
                                                className={styles.favouriteIcon}
                                            />
                                        ) : (
                                            <FavoriteBorderIcon
                                                className={styles.favouriteIcon}
                                            />
                                        )}
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            )}

            <Box className={styles.tabsContainer}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={selectedTab}
                        onChange={onTabChanged}
                        className={styles.tabControl}
                        sx={{ minHeight: "36px", height: "36px" }}
                    >
                        <Tab label="Summary" {...a11yProps(0)} disableRipple />
                        <Tab
                            label="Specification"
                            {...a11yProps(1)}
                            disableRipple
                        />
                        {/* <Tab
                            label="Seller Details"
                            {...a11yProps(2)}
                            disableRipple
                        /> */}
                    </Tabs>
                </Box>
                <div className={styles.tabContentContainer}>
                    <TabPanel value={selectedTab} index={0}>
                        {watchDetails.summary.split("||").map((line, index) =>
                            line === "" ? (
                                <div
                                    key={index}
                                    className={styles.summarySpacer}
                                />
                            ) : (
                                <Typography
                                    key={index}
                                    className={styles.summaryText}
                                >
                                    {Parser(line)}
                                </Typography>
                            ),
                        )}
                        <Stack
                            direction="column"
                            className={styles.summaryLineContainer}
                        >
                            <div className={styles.summaryLine} />
                        </Stack>
                        <Typography className={styles.suffixText}>
                            All watches listed for auction are cleared against
                            The Watch Register database before being accepted
                            for consignment. A certificate to reflect this is
                            available to the winning bidder on request.
                        </Typography>
                        <Typography className={styles.suffixText}>
                            Please be aware that all watches should be assumed
                            to require a service to bring them back within
                            manufacturer’s specifications. Some watches will
                            remain within the manufacturer’s warranty period at
                            the point of being auctioned and that warranty may
                            be transferrable to the winning bidder. Please check
                            before bidding.
                        </Typography>
                        <Typography className={styles.suffixText}>
                            All activity on this site is subject to the Terms
                            and Conditions which can be found here.
                        </Typography>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <div className={styles.specificationContainer}>
                            <Stack direction="column">
                                <Stack
                                    direction="row"
                                    className={
                                        styles.specificationValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.specificationLabel}
                                    >
                                        Model Number:
                                    </Typography>
                                    <Typography
                                        className={styles.specificationValue}
                                    >
                                        {watchDetails.specification.modelNumber}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    className={
                                        styles.specificationValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.specificationLabel}
                                    >
                                        Condition:
                                    </Typography>
                                    <Typography
                                        className={styles.specificationValue}
                                    >
                                        {watchDetails.specification.condition}
                                    </Typography>
                                    <ConditionTooltip isLarge={true} />
                                </Stack>
                                <Stack
                                    direction="row"
                                    className={
                                        styles.specificationValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.specificationLabel}
                                    >
                                        Material:
                                    </Typography>
                                    <Typography
                                        className={styles.specificationValue}
                                    >
                                        {watchDetails.specification.material}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    className={
                                        styles.specificationValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.specificationLabel}
                                    >
                                        Movement:
                                    </Typography>
                                    <Typography
                                        className={styles.specificationValue}
                                    >
                                        {watchDetails.specification.movement}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    className={
                                        styles.specificationValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.specificationLabel}
                                    >
                                        Lot:
                                    </Typography>
                                    <Typography
                                        className={styles.specificationValue}
                                    >
                                        {watchDetails.specification.lot}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack direction="column">
                                <Stack
                                    direction="row"
                                    className={
                                        styles.specificationValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.specificationLabel}
                                    >
                                        Case:
                                    </Typography>
                                    <Typography
                                        className={styles.specificationValue}
                                    >
                                        {watchDetails.specification.case}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    className={
                                        styles.specificationValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.specificationLabel}
                                    >
                                        Box:
                                    </Typography>
                                    <Typography
                                        className={styles.specificationValue}
                                    >
                                        {watchDetails.specification.box}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    className={
                                        styles.specificationValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.specificationLabel}
                                    >
                                        Papers:
                                    </Typography>
                                    <Typography
                                        className={styles.specificationValue}
                                    >
                                        {watchDetails.specification.papers}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    className={
                                        styles.specificationValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.specificationLabel}
                                    >
                                        Waterproof:
                                    </Typography>
                                    <Typography
                                        className={styles.specificationValue}
                                    >
                                        {watchDetails.specification.waterproof}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </div>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <div className={styles.sellerDetailsContainer}>
                            <Stack direction="column">
                                <Stack
                                    direction="row"
                                    className={
                                        styles.sellerDetailsValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.sellerDetailsLabel}
                                    >
                                        Seller:
                                    </Typography>
                                    <Typography
                                        className={styles.sellerDetailsValue}
                                    >
                                        {watchDetails.sellerInfo.user}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    className={
                                        styles.sellerDetailsValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.sellerDetailsLabel}
                                    >
                                        Seller Type:
                                    </Typography>
                                    {watchDetails.sellerInfo.sellerType && (
                                        <>
                                            <Typography
                                                className={
                                                    styles.sellerDetailsValue
                                                }
                                            >
                                                {
                                                    watchDetails.sellerInfo
                                                        .sellerType
                                                }
                                            </Typography>
                                            {/* <SellerDetailsGavelIcon className={styles.gavelIcon} />
                                        <CartIcon className={styles.cartIcon} /> */}
                                        </>
                                    )}
                                </Stack>
                            </Stack>
                            <Stack direction="column">
                                <Stack
                                    direction="row"
                                    className={
                                        styles.sellerDetailsValueContainer
                                    }
                                >
                                    <Typography
                                        className={styles.sellerDetailsLabel}
                                    >
                                        Location:
                                    </Typography>
                                    <Typography
                                        className={styles.sellerDetailsValue}
                                    >
                                        {watchDetails.sellerInfo.location}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </div>
                    </TabPanel>
                </div>
            </Box>

            {scrolling && <BiddingLabel />}

            {watchDetails.status === "ComingSoon" && (
                <>
                    <Stack direction="column" className={styles.emailContainer}>
                        <Typography className={styles.emailText}>
                            We are opening the Watch Shuffle showroom for
                            browsing in July and your opportunity to bid on
                            watches will follow in Q4 2023.
                        </Typography>
                        <Typography className={styles.emailText}>
                            Our launch will be supported with advertising across
                            TV, Outdoor media and competitions!
                        </Typography>
                        <Typography className={styles.emailText}>
                            Register below to be kept updated!
                        </Typography>
                        <Stack
                            direction="column"
                            className={styles.emailFormContainer}
                        >
                            <iframe
                                className={styles.emailForm}
                                title="SendGridSignUpForm"
                                src="https://cdn.forms-content.sg-form.com/a22d90cc-b81c-11ed-a8e7-f211d972171f"
                            />
                        </Stack>
                    </Stack>

                    <Stack
                        direction="row"
                        className={styles.auctionStartsContainerMobile}
                    >
                        <Stack
                            direction="column"
                            className={styles.contentsContainer}
                        >
                            <Typography className={styles.auctionStartsLabel}>
                                {watchDetails.auctionStartDate ||
                                watchDetails.auctionStartDateDescription
                                    ? "Auction Starts"
                                    : "Auction Ends"}
                            </Typography>
                            <Typography className={styles.auctionStartsValue}>
                                {watchDetails.auctionStartDate ||
                                watchDetails.auctionStartDateDescription
                                    ? getDisplayDate(
                                          watchDetails.auctionStartDate,
                                          watchDetails.auctionStartDateDescription,
                                      )
                                    : getDisplayDate(
                                          watchDetails.auctionEndDate,
                                          watchDetails.auctionEndDateDescription,
                                      )}
                            </Typography>
                        </Stack>
                    </Stack>
                </>
            )}

            {watchDetails.status === "Live" && (
                <Stack direction="row" className={styles.createBidContainer}>
                    <Stack
                        direction="column"
                        className={styles.minBidContainer}
                    >
                        <Stack
                            direction="row"
                            className={styles.bidEntryContainer}
                        >
                            <Stack
                                direction="column"
                                className={styles.inputContainer}
                            >
                                <Typography className={styles.minBidLabel}>
                                    Minimum Bid: £{minBidAmountString}
                                </Typography>
                                <TextField
                                    className={styles.bidInput}
                                    fullWidth
                                    id="bidInput"
                                    InputProps={{
                                        inputComponent:
                                            NumericFormatCustom as any,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Stack direction="row">
                                                    {bidAmount !== undefined ? (
                                                        <IconButton
                                                            className={
                                                                styles.clearButton
                                                            }
                                                            onClick={onClearBid}
                                                        >
                                                            <ClearIcon />
                                                        </IconButton>
                                                    ) : null}
                                                </Stack>
                                            </InputAdornment>
                                        ),
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontSize: "11px",
                                            fontWeight: "400",
                                        },
                                    }}
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                display: "none",
                                            },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                    }}
                                    placeholder={`Min. £${minBidAmountString}`}
                                    value={
                                        bidAmount === undefined ? "" : bidAmount
                                    }
                                    onChange={onBidAmountChange}
                                    error={!!bidError}
                                    helperText={bidError}
                                    variant="standard"
                                    disabled={!user?.email_verified}
                                />
                            </Stack>
                        </Stack>

                        <Stack
                            direction="row"
                            className={styles.bidButtonsContainer}
                        >
                            {/* <Button className={styles.addFavouritesButton} variant="contained" disableElevation>
                                <Typography className={styles.addFavouritesText}>Add to Favourites</Typography>
                            </Button> */}
                            <Button
                                className={styles.placeBidButton}
                                variant="contained"
                                disableElevation
                                onClick={onBidClick}
                                disabled={!!bidError || isPreview}
                            >
                                <Typography className={styles.placeBidText}>
                                    Place a Bid
                                </Typography>
                            </Button>
                        </Stack>
                    </Stack>

                    {isDesktop && (
                        <Stack
                            direction="column"
                            className={styles.auctionEndsContainer}
                        >
                            <Typography className={styles.auctionEndsLabel}>
                                Auction Ends
                            </Typography>
                            <EndTimer
                                endDate={watchDetails.auctionEndDate}
                                endDateDescription={
                                    watchDetails.auctionEndDateDescription
                                }
                                isLarge={true}
                                onCountdownFinished={onCountdownFinished}
                            />
                        </Stack>
                    )}
                </Stack>
            )}

            {watchDetails.status === "EndedSold" &&
                (watchDetails.currentBidUser &&
                watchDetails.currentBidUser === user?.public_display_name ? (
                    <Stack direction="row" className={styles.endSoldContainer}>
                        <Stack
                            direction="column"
                            className={styles.winnerContainer}
                        >
                            <Typography className={styles.winnerText}>
                                Congratulations on your winning bid!
                            </Typography>
                            <Typography className={styles.winnerText}>
                                An email will be sent with further details on
                                how to get in touch with the seller to arrange
                                the exchange.
                            </Typography>
                        </Stack>
                        <Stack
                            direction="column"
                            className={styles.auctionEndedContainer}
                        >
                            <Typography
                                className={styles.auctionEndedLabel}
                                noWrap
                            >
                                Auction Ended
                            </Typography>
                            <Typography
                                className={styles.auctionEndedValue}
                                noWrap
                            >
                                {getDisplayDate(
                                    watchDetails.auctionEndDate,
                                    watchDetails.auctionEndDateDescription,
                                )}
                            </Typography>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack direction="row" className={styles.endSoldContainer}>
                        <div />
                        <Stack
                            direction="column"
                            className={styles.auctionEndedContainer}
                        >
                            <Typography
                                className={styles.auctionEndedLabel}
                                noWrap
                            >
                                Auction Ended
                            </Typography>
                            <Typography
                                className={styles.auctionEndedValue}
                                noWrap
                            >
                                {getDisplayDate(
                                    watchDetails.auctionEndDate,
                                    watchDetails.auctionEndDateDescription,
                                )}
                            </Typography>
                        </Stack>
                    </Stack>
                ))}

            {!isDesktop && watchDetails.status === "Live" && (
                <Stack
                    direction="row"
                    className={styles.auctionEndsContainerMobile}
                >
                    <Stack
                        direction="column"
                        className={styles.contentsContainer}
                    >
                        <Typography className={styles.auctionEndsLabel}>
                            Auction Ends
                        </Typography>
                        <EndTimer
                            endDate={watchDetails.auctionEndDate}
                            endDateDescription={
                                watchDetails.auctionEndDateDescription
                            }
                            isLarge={true}
                            onCountdownFinished={onCountdownFinished}
                        />
                    </Stack>
                </Stack>
            )}

            <div className={styles.bidHistoryContainer}>
                <Accordion
                    elevation={0}
                    expanded={showBidHistory}
                    onChange={() => setShowBidHistory((prev) => !prev)}
                >
                    <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon className={styles.expandIcon} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={styles.titleLabel}>
                            Bid History
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {watchDetails.bidHistory.length ? (
                            <BidHistoryTree
                                bidHistory={watchDetails.bidHistory}
                            />
                        ) : (
                            <Stack
                                direction="column"
                                className={styles.bidHistoryNoItemsContainer}
                            >
                                <Typography
                                    className={styles.bidHistoryNoItems}
                                >
                                    Currently no history
                                </Typography>
                            </Stack>
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className={styles.conditionContainer}>
                <Accordion
                    elevation={0}
                    expanded={showCondition}
                    onChange={() => setShowCondition((prev) => !prev)}
                >
                    <AccordionSummary
                        expandIcon={
                            <ExpandMoreIcon className={styles.expandIcon} />
                        }
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={styles.titleLabel}>
                            Condition
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {watchDetails.conditionDetails.images.length > 0 ||
                        watchDetails.conditionDetails.description ? (
                            <Stack
                                direction="column"
                                className={styles.conditionContentContainer}
                            >
                                {watchDetails.conditionDetails.images.length >
                                0 ? (
                                    <div
                                        className={styles.otherImagesContainer}
                                    >
                                        <LightGallery
                                            licenseKey={LightGalleryLicense}
                                            speed={500}
                                            onInit={onInit}
                                            plugins={[lgThumbnail, lgZoom]}
                                            getCaptionFromTitleOrAlt={false}
                                            showCloseIcon={true}
                                        >
                                            {watchDetails.conditionDetails.images.map(
                                                (image, index) => (
                                                    <a
                                                        key={`${watchDetails.id}_${image.mediaUrl}`}
                                                        href={image.mediaUrl}
                                                    >
                                                        <img
                                                            alt="img"
                                                            src={
                                                                image.thumbnailUrl
                                                            }
                                                            className={
                                                                index === 0
                                                                    ? styles.thumbnailImageFirst
                                                                    : styles.thumbnailImage
                                                            }
                                                        />
                                                    </a>
                                                ),
                                            )}
                                        </LightGallery>
                                        {watchDetails.conditionDetails.images
                                            .length > 3 && (
                                            <a
                                                href="/"
                                                onClick={handleGalleryShow}
                                            >
                                                <div
                                                    className={
                                                        styles.moreContainer
                                                    }
                                                >
                                                    <Typography
                                                        className={
                                                            styles.moreText
                                                        }
                                                    >
                                                        +
                                                        {watchDetails
                                                            .conditionDetails
                                                            .images.length - 3}
                                                    </Typography>
                                                </div>
                                            </a>
                                        )}
                                    </div>
                                ) : null}

                                {watchDetails.conditionDetails.description ? (
                                    <Typography
                                        className={styles.conditionText}
                                    >
                                        {
                                            watchDetails.conditionDetails
                                                .description
                                        }
                                    </Typography>
                                ) : null}
                            </Stack>
                        ) : (
                            <Stack
                                direction="row"
                                className={styles.conditionNoInfoContainer}
                            >
                                <Typography className={styles.conditionNoInfo}>
                                    No additional condition information
                                </Typography>
                            </Stack>
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>

            {/* <Stack direction='column' className={styles.commentContainer}>
                <Stack direction='row' className={styles.commentInputContainer}>
                    <TextField 
                        className={styles.commentInput} 
                        multiline={true}
                        rows={5}
                        variant='filled' 
                        placeholder='Leave a comment here' />
                </Stack>
                <Stack direction='row' className={styles.postCommentContainer}>
                    <Button className={styles.postCommentButton} variant="contained" disableElevation>
                        <Typography className={styles.postCommentText}>Post Comment</Typography>
                    </Button>
                </Stack>
            </Stack> */}

            <BidDialog
                isOpen={showBidConfirmDialog}
                onClose={onCloseBidConfirmDialog}
                watchDetails={bidDialogWatchDetails}
                newBid={bidDialogBidAmount}
            />
        </Stack>
    );
};

export default ItemDetails;