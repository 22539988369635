import { ListingDetail, ListingDetailAttachment, ListingDetailBid } from "@hmxlabs/dax-client";
import { BidHistoryItem, ConditionInfo, SpecificationInfo, WatchInfoBuyDetails } from "../models/WatchInfoBuyDetails";
import { format, formatDistanceToNow } from "date-fns";
import { ItemDetails } from "../models/ItemDetails";
import { WatchListing, WatchListingConditionInfo, WatchListingSellerDetails, WatchListingSpecifications } from "../models/WatchListing";
import { Attachment } from "../models/Attachment";

export const defaultMinBidIncrement = 100;

export const isToday = (date: Date) => {
    const now = new Date();
  
      return date.getDate() === now.getDate() &&
           date.getMonth() === now.getMonth() &&
           date.getFullYear() === now.getFullYear();
}

export const findWinningBid = (bidHistory: BidHistoryItem[]) => {
    let winningBid: BidHistoryItem|undefined = undefined;

    for (let i = 0; i < bidHistory.length; i++) {
        const item = bidHistory[i];
        if (!winningBid || item.bidDate > winningBid.bidDate) {
            winningBid = item;
        }
    }

    return winningBid;
}

export const convertToBidHistoryItem = (bid: ListingDetailBid): BidHistoryItem => {
    const bidDate = new Date(bid.lastModifiedOn ? bid.lastModifiedOn : bid.createdOn);
    const bidDateString = isToday(bidDate) ? 'Today ' + format(bidDate, 'HH:mm') : format(bidDate, 'dd-MMM-yyyy HH:mm');
    const bidDateDescription = formatDistanceToNow(bidDate, {includeSeconds: true}) + ' ago';
    
    return {
        bidId: `${bid.lastModifiedOn ? bid.lastModifiedOn : bid.createdOn}|${
            bid.createdBy
        }|${bid.amount}`,
        bidDate,
        bidDateString,
        bidDateDescription,
        bidAmount: bid.amount,
        user: bid.createdBy,
    };
};

const convertToAttachment = (listingAttachment: ListingDetailAttachment) => {
    const attachment: Attachment = listingAttachment;
    return attachment;
}

const getShowroomAttachment = (listingAttachments: ListingDetailAttachment[]) => {
    const showroomAttachments = listingAttachments.find(
        (attachment) => attachment.category === "showroom",
    );

    return showroomAttachments ? convertToAttachment(showroomAttachments) : undefined;
}

export const convertToWatchInfoBuyDetails = (listing: ListingDetail): WatchInfoBuyDetails => {
    const allProperties = [
        ...(listing.productProperties ?? []),
        ...(listing.itemProperties ?? []),
        ...(listing.listingProperties ?? [])
    ];
    const yearItem = allProperties.find(item => item.name === 'year');
    const auctionStartDateDescriptionItem = allProperties.find(item => item.name === 'auctionStarts');
    const auctionEndDateDescriptionItem = allProperties.find(item => item.name === 'auctionEnds');

    const specification: SpecificationInfo = {
        condition: listing.condition,
        modelNumber: '',
        material: '',
        movement: '',
        lot: '',
        case: '',
        box: '',
        papers: '',
        waterproof: '',
    };

    const conditionDetails: ConditionInfo = {
        description: '',
        images: listing.attachments
            .filter(attachment => attachment.category === 'condition')
            .sort((x, y) => x.sortOrder === y.sortOrder ? 0 : (x.sortOrder < y.sortOrder ? -1 : 1))
            .map(convertToAttachment),
    };

    allProperties.forEach(prop => {
        switch (prop.name) {
            case 'modelNumber': specification.modelNumber = prop.value; break;
            case 'material': specification.material = prop.value; break;
            case 'movement': specification.movement = prop.value; break;
            case 'lot': specification.lot = prop.value; break;
            case 'case': specification.case = prop.value; break;
            case 'box': specification.box = prop.value; break;
            case 'papers': specification.papers = prop.value; break;
            case 'waterproof': specification.waterproof = prop.value; break;
            case 'conditionInfo': conditionDetails.description = prop.value; break;
        }
    });

    const bidHistory = listing.bids.map(bid => convertToBidHistoryItem(bid));
    const currentBid = listing.bids.length ? listing.bids[0] : {amount: 0, createdBy: ''};
    const minBid = listing.minBid ? listing.minBid : currentBid.amount + defaultMinBidIncrement;

    return {
        id: listing.id,
        brand: listing.manufacturer,
        model: listing.model,
        year: yearItem?.value ? yearItem.value : "Unknown",
        auctionStartDate: listing.startsOn
            ? new Date(listing.startsOn)
            : undefined,
        auctionEndDate: listing.endsOn ? new Date(listing.endsOn) : undefined,
        auctionStartDateDescription: auctionStartDateDescriptionItem ? auctionStartDateDescriptionItem.value : undefined,
        auctionEndDateDescription: auctionEndDateDescriptionItem ? auctionEndDateDescriptionItem.value : undefined,
        reservePrice: listing.reservePrice,
        createdOn: new Date(listing.createdOn),
        summary: listing.description,
        specification,
        conditionDetails,
        images: listing.attachments
            .filter((attachment) => attachment.category === "details")
            .sort((x, y) =>
                x.sortOrder === y.sortOrder
                    ? 0
                    : x.sortOrder < y.sortOrder
                    ? -1
                    : 1,
            )
            .map(convertToAttachment),
        showroomImage: getShowroomAttachment(listing.attachments),
        status: listing.status,
        bidsError: listing.bidsError,
        bids: listing.bids.length,
        currentBid: currentBid.amount,
        currentBidUser: currentBid.createdBy,
        minBid,
        bidHistory,
        winningBid: listing.status === 'EndedSold' ? findWinningBid(bidHistory) : undefined,
        sellerId: listing.sellerId,
        isFavourite: listing.isWatchedByMe,
        savedAsFavouriteCount: listing.watchCount,

        // TODO: Get the following fields from the API when available
        sellerType: "private",
        sellerInfo: {
            user: "Managed sale",
            location: "London",
            sellerType: "Private seller",
        },
        comments: [],
        userMaxBid: listing.userMaxBid,
    };
};

export const convertItemToWatchListing = (itemDetails: ItemDetails, sellerDetails: WatchListingSellerDetails, attachments: Attachment[]): WatchListing => {
    const allProperties = [...itemDetails.product.properties, ...itemDetails.properties];

    return {
        itemId: itemDetails.id,
        itemStatus: itemDetails.status,
        productId: itemDetails.productId,
        brand: itemDetails.product.manufacturer,
        model: itemDetails.product.model,
        year: itemDetails.properties.find(p => p.name === 'year')?.value,
        createdOn: new Date(itemDetails.createdOn),
        sellerId: itemDetails.sellerId,
        summary: itemDetails.description,
        specification: {
            modelNumber: allProperties.find(p => p.name === 'modelNumber')?.value,
            condition: itemDetails.condition,
            material: allProperties.find(p => p.name === 'material')?.value,
            movement: allProperties.find(p => p.name === 'movement')?.value,
            lot: allProperties.find(p => p.name === 'lot')?.value,
            case: allProperties.find(p => p.name === 'case')?.value,
            box: allProperties.find(p => p.name === 'box')?.value,
            papers: allProperties.find(p => p.name === 'papers')?.value,
            waterproof: allProperties.find(p => p.name === 'waterproof')?.value,
        },
        conditionDetails: {
            description: itemDetails.conditionText,
            images: attachments.filter(attachment => attachment.category === 'condition')
            .sort((x, y) => x.sortOrder === y.sortOrder ? 0 : (x.sortOrder < y.sortOrder ? -1 : 1))
        },
        sellerInfo: sellerDetails,
        comments: [],
        images: attachments.filter(attachment => attachment.category === 'details')
        .sort((x, y) =>
            x.sortOrder === y.sortOrder
                ? 0
                : x.sortOrder < y.sortOrder
                ? -1
                : 1,
        ),
        showroomImage: attachments.find(attachment => attachment.category === 'showroom'),
        sellerType: sellerDetails.sellerType
    };
}

export const convertListingToWatchListing = (listing: ListingDetail, sellerDetails: WatchListingSellerDetails): WatchListing => {
    const allProperties = [
        ...(listing.productProperties ?? []),
        ...(listing.itemProperties ?? []),
        ...(listing.listingProperties ?? [])
    ];
    const yearItem = allProperties.find(item => item.name === 'year');
    const auctionStartDateDescriptionItem = allProperties.find(item => item.name === 'auctionStarts');
    const auctionEndDateDescriptionItem = allProperties.find(item => item.name === 'auctionEnds');

    const specification: WatchListingSpecifications = {
        condition: listing.condition,
        modelNumber: '',
        material: '',
        movement: '',
        lot: '',
        case: '',
        box: '',
        papers: '',
        waterproof: '',
    };

    const conditionDetails: WatchListingConditionInfo = {
        description: '',
        images: listing.attachments
            .filter(attachment => attachment.category === 'condition')
            .sort((x, y) => x.sortOrder === y.sortOrder ? 0 : (x.sortOrder < y.sortOrder ? -1 : 1))
    };

    allProperties.forEach(prop => {
        switch (prop.name) {
            case 'modelNumber': specification.modelNumber = prop.value; break;
            case 'material': specification.material = prop.value; break;
            case 'movement': specification.movement = prop.value; break;
            case 'lot': specification.lot = prop.value; break;
            case 'case': specification.case = prop.value; break;
            case 'box': specification.box = prop.value; break;
            case 'papers': specification.papers = prop.value; break;
            case 'waterproof': specification.waterproof = prop.value; break;
            case 'conditionInfo': conditionDetails.description = prop.value; break;
        }
    });

    const bidHistory = listing.bids.map(bid => convertToBidHistoryItem(bid));
    const currentBid = listing.bids.length ? listing.bids[0] : {amount: 0, createdBy: ''};
    const minBid = listing.minBid ? listing.minBid : currentBid.amount + defaultMinBidIncrement;

    return {
        listingId: listing.id,
        listingStatus: listing.status,
        itemId: listing.itemId,
        itemStatus: listing.itemStatus,
        productId: listing.productId,
        brand: listing.manufacturer,
        model: listing.model,
        year: yearItem?.value ? yearItem.value : "Unknown",
        auctionStartDate: listing.startsOn
            ? new Date(listing.startsOn)
            : undefined,
        auctionEndDate: listing.endsOn ? new Date(listing.endsOn) : undefined,
        auctionStartDateDescription: auctionStartDateDescriptionItem ? auctionStartDateDescriptionItem.value : undefined,
        auctionEndDateDescription: auctionEndDateDescriptionItem ? auctionEndDateDescriptionItem.value : undefined,
        reservePrice: listing.reservePrice,
        createdOn: new Date(listing.createdOn),
        summary: listing.description,
        specification,
        conditionDetails,
        images: listing.attachments
            .filter((attachment) => attachment.category === "details")
            .sort((x, y) =>
                x.sortOrder === y.sortOrder
                    ? 0
                    : x.sortOrder < y.sortOrder
                    ? -1
                    : 1,
            ),
        showroomImage:
            listing.attachments.find(
                (attachment) => attachment.category === "showroom",
            ),
        bidsError: listing.bidsError,
        bids: listing.bids.length,
        currentBid: currentBid.amount,
        currentBidUser: currentBid.createdBy,
        minBid,
        bidHistory,
        winningBid: listing.status === 'EndedSold' ? findWinningBid(bidHistory) : undefined,
        sellerId: listing.sellerId,
        isFavourite: listing.isWatchedByMe,
        savedAsFavouriteCount: listing.watchCount,
        sellerInfo: sellerDetails,
        sellerType: sellerDetails.sellerType,
        comments: [],
    };
}