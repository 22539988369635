import { nanoid } from "@reduxjs/toolkit";
import { loggingService } from "../../utils/logging/logging";
import { ListingStatusCounts } from "../models/ListingStatusCounts";
import {
    ProductInfo,
    ProductResult,
    ProductGroups,
    ItemCreateResponse,
    AttachmentRequest,
} from "../models/ProductInfo";
import { WatchInfoBuyDetails } from "../models/WatchInfoBuyDetails";
import {
    daxApi,
    listingDetailById,
    listingDetailFindAll,
    ListingDetail,
    listingStatusCountFindAll,
} from "@hmxlabs/dax-client";
import { ItemCreateRequest, AttachmentResponse, Body, ItemUpdateRequest, ListingCreateRequest, AttachmentDirectUploadUrlResponse, ListingUpdateRequest } from "@hmxlabs/dax-client/build/services/generated/dax-api/data-contracts";
import { addYears, format } from "date-fns";
import { ItemDetails } from "../models/ItemDetails";
import { WatchListingSellerDetails, WatchListing } from "../models/WatchListing";
import { Attachment } from "../models/Attachment";
import { convertItemToWatchListing, convertListingToWatchListing, convertToWatchInfoBuyDetails } from "./converter-service";
import { GetListingResponse } from "../models/GetListingResponse";
import { ItemStatusCountsForAdmin } from "../models/ItemStatusCountsForAdmin";
import { ListingStatusCountsForAdmin } from "../models/ListingStatusCountsForAdmin";
import { User } from "../models/User";
import _ from 'lodash';

const {log} = loggingService('service:auction-service');

export const getWatchBuyDetails = async (
    id: string,
): Promise<WatchInfoBuyDetails> => {
    const cancelToken = nanoid();
    const listing = await listingDetailById(id, { cancelToken });
    return convertToWatchInfoBuyDetails(listing);
};

const convertToListingStatus = (status: string) => {
    switch (status) {
        case "ComingSoon":
            return "ComingSoon";
        case "Live":
            return "Live";
        case "EndedSold":
            return "EndedSold";
        default:
            return "";
    }
};

export const getWatchesByStatus = async (
    status: string,
): Promise<WatchInfoBuyDetails[]> => {
    log("getWatchesByStatus called");
    const listingStatus = convertToListingStatus(status);
    const cancelToken = nanoid();
    const listings = await listingDetailFindAll("auction", listingStatus, {
        cancelToken,
    });
    return listings.map(convertToWatchInfoBuyDetails);
};

export const getWatchStatusCounts = async (): Promise<ListingStatusCounts> => {
    const cancelToken = nanoid();
    const counts = await listingStatusCountFindAll("auction", undefined, {
        cancelToken,
    });
    return {
        ComingSoon: counts.find((x) => x.status === "ComingSoon")?.count ?? 0,
        Live: counts.find((x) => x.status === "Live")?.count ?? 0,
        EndedSold: counts.find((x) => x.status === "EndedSold")?.count ?? 0,
    };
};

export interface BidResponse {
    Amount: number;
    CreatedBy: string;
    CreatedOn: string;
    LastModifiedBy: string;
    LastModifiedOn: string;
}

export const createBid = (
    listingId: string,
    amount: number,
): Promise<BidResponse> => {
    return daxApi.bidsCreate<BidResponse>(listingId, { type: 2, amount });
};

export const getWatchesForSeller = async (): Promise<WatchInfoBuyDetails[]> => {
    const cancelToken = nanoid();
    const listings = await daxApi.listingDetailFindAllForSeller<
        ListingDetail[]
    >(undefined, { cancelToken });
    return listings.map((listing) => convertToWatchInfoBuyDetails(listing));
};

export const getWatchesForBuyer = async (): Promise<WatchInfoBuyDetails[]> => {
    const cancelToken = nanoid();
    const listings = await daxApi.listingDetailFindAllForBuyer<
        ListingDetail[]
    >(undefined, { cancelToken });
    return listings.map((listing) => convertToWatchInfoBuyDetails(listing));
};

export const getProducts = async (): Promise<ProductGroups> => {
    const cancelToken = nanoid();
    const listings = await daxApi.productFindAllByTenantId<ProductResult>({
        cancelToken,
    });

    if (listings?.value?.length) {
        let data: ProductGroups = {};
        let allProducts: ProductInfo[] = listings.value;
        allProducts.forEach((item) => {
            if (!(item["manufacturer"] in data)) {
                data[item["manufacturer"]] = [];
            }
            data[item["manufacturer"]].push({
                id: item["id"],
                productClassId: item["productClassId"],
                model: item["model"],
                variant: item["variant"],
                properties: item["properties"]
            });
        });
        return data;
    }
    return {};
};

export const generateNewProduct = async (product: ItemCreateRequest) => {
    try {
        const itemResponse = await daxApi.itemCreateDraftOrProposal<ItemCreateResponse>(
            product,
        );

        if (!itemResponse?.id) {
            throw new Error("Invalid item response Id received");
        }
        return itemResponse;
    } catch (error) {
        throw new Error("There is something wrong, please try again..")
    }
};

export const createAttachment = async (
    itemId: string,
    attachment: AttachmentRequest,
    category?: string
) => {
    try {
        let img:string[] = [];
        attachment?.variants.forEach((item) => {
            let itemType = item.split('/').pop();
            if(itemType === 'main') img[0] = item;
            else if(itemType === 'thumbnail') img[1] = item;
            else img[2] = item;
        })
       
        const attachmentInfo = {
            cdnId: attachment.id,
            mediaType: "jpg",
            mediaUrl: img[0],
            thumbnailUrl: img[1],
            category: category ? category : "details",
        };
        let attachRes: AttachmentResponse = await daxApi.attachmentCreate(itemId, attachmentInfo);
        if (!attachRes?.id) {
            throw new Error("Invalid attachment response Id received");
        } else return attachRes;
    } catch (error) {
        throw Error("Invalid attachment response Id received");
    }
};

export const watchUnwatch = async (
    id: string,
    body: Body,
) => {
    try {
        await daxApi.listingWatchOrNot(id, body);        
    } catch (error) {
        throw Error("Failed to favorite, please try again");
    }
}

export const getFavWatchesByStatus = async (
    status: string,
): Promise<WatchInfoBuyDetails[]> => {
    const listingStatus = convertToListingStatus(status);
    const cancelToken = nanoid();
    const listings = await listingDetailFindAll("auction", listingStatus, {
        cancelToken,
    });
    return listings.filter(item => item.isWatchedByMe).map(convertToWatchInfoBuyDetails);
};

const getUserDetails = async(userId: string): Promise<User|null> => {
    try {
        const cancelToken = nanoid();
        const result = await daxApi.tenantAdminGetById<User>(userId, {
            cancelToken
        });

        return result;
    } catch (error) {
        log(`Error retrieving user details for id ${userId}`, error);       
        return null; 
    }
}

const getAllSellerDetails = async(sellerIds: string[]): Promise<Record<string, WatchListingSellerDetails>> => {
    const sellerIdToDetailsMap: Record<string, WatchListingSellerDetails> = {};

    await Promise.all(sellerIds.map(async (sellerId) => {
        const user = await getUserDetails(sellerId);

        if (user) {
            sellerIdToDetailsMap[sellerId] = {
                user: sellerId,
                location: user.billingAddress ?? 'unknown',
                sellerType: 'Private', // TODO: fix when available in dax api
                email: user.email,
                phone: user.phoneNumber ?? 'unknown',
            };
        } else {
            sellerIdToDetailsMap[sellerId] = {
                user: sellerId,
                location: 'unknown',
                sellerType: 'unknown',
                email: 'unknown',
                phone: 'unknown',
            };
        }
    }));

    return sellerIdToDetailsMap;
}

export const getItemsForStatus = async(status: string): Promise<WatchListing[]> => {
    const cancelToken = nanoid();
    const results = await daxApi.itemFindAllForAdmin<ItemDetails[]>({
        includeInactive: false, 
        itemStatus: status
    }, {
        cancelToken
    });

    const sellerIds = _.uniq(results.map(x => x.sellerId));
    const sellerIdToDetailsMap = await getAllSellerDetails(sellerIds);

    const watchListings = await Promise.all(results.map(async (result) => {
        const attachments = await daxApi.attachmentFindAll<Attachment[]>(result.id, {includeInactive: false}, {cancelToken: nanoid()});
        return convertItemToWatchListing(result, sellerIdToDetailsMap[result.sellerId], attachments);
    }));

    return watchListings;
}

export const getListingForStatus = async(status: string): Promise<WatchListing[]> => {
    const cancelToken = nanoid();
    const results = await daxApi.listingDetailFindAllByTenantIdForAdmin<ListingDetail[]>({
        listingType: "auction", 
        listingStatus: status
    }, 
    {cancelToken});

    const sellerIds = _.uniq(results.map(x => x.sellerId));
    const sellerIdToDetailsMap = await getAllSellerDetails(sellerIds);

    const watchListings = await Promise.all(results.map(async (result) => {
        return convertListingToWatchListing(result, sellerIdToDetailsMap[result.sellerId]);
    }));

    return watchListings;
}

export const deleteAttachment = async (
    itemId: string,
    attachmentId: string
) => {
    try {
        return await daxApi.attachmentDelete(itemId, attachmentId);
    } catch (error) {
        throw Error("Invalid attachment response Id received");
    }
}

export const updateAttachment = async (
    itemId: string,
    attchmentId: string,
    body: {
        category?: string;
        sortOrder?: number;
    }
): Promise<AttachmentResponse[]> => {
    try {
        return await daxApi.attachmentUpdate(itemId, attchmentId, body);
    } catch (error) {
        throw Error("Faild to update attachment");
    }
}

export const getBatchDirectUploadUrl = async (): Promise<AttachmentDirectUploadUrlResponse> => {
    return await daxApi.attachmentGetBatchDirectUploadUrl<AttachmentDirectUploadUrlResponse>();
}

export const updateItemDetails = async (listing: WatchListing) => {
    const itemUpdateRequest: ItemUpdateRequest = {
        productId: listing.productId,
        description: listing.summary,
        dateOfManufacture: '1970-01-01', // we don't use this field so just hard code it to a specific value
        condition: listing.specification.condition,
        conditionText: listing.conditionDetails.description,
        properties: [
            {name: 'modelNumber', value: listing.specification.modelNumber ?? ''},
            {name: 'year', value: listing.year ?? ''},
            {name: 'box', value: listing.specification.box ?? ''},
            {name: 'papers', value: listing.specification.papers ?? ''},
            {name: 'conditionInfo', value: listing.conditionDetails.description ?? ''},
        ]
    };

    log('Calling itemUpdateForAdmin', itemUpdateRequest);
    await daxApi.itemUpdateForAdmin(listing.itemId, itemUpdateRequest);
    log('itemUpdateForAdmin success');
}

export const updateListingDetails = async (listing: WatchListing) => {
    if (!listing.listingId) {
        return;
    }

    log('updateListingDetails - Called with listing:', listing);
    log(`updateListingDetails - Retrieving orginal listing for id ${listing.listingId}`);
    const originalListing = await daxApi.listingGetForAdminById<GetListingResponse>(listing.listingId, {
        includeInactive: false,
    });

    log('updateListingDetails - Orginal listing:', originalListing);
    if (!originalListing) {
        // TODO: throw an error here and handle it further up
        return;
    }

    
    log('updateListingDetails - listing.auctionStartDate:', listing.auctionStartDate);
    log('updateListingDetails - listing.auctionEndDate:', listing.auctionEndDate);
    const startDate = listing.auctionStartDate ? listing.auctionStartDate : new Date(originalListing.startsOn!);
    const startDateString = format(startDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
    const endDate = listing.auctionEndDate ? listing.auctionEndDate : new Date(originalListing.endsOn!);
    const endDateString = format(endDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
    
    const listingUpdateRequest: ListingUpdateRequest = {
        name: originalListing.name,
        type: originalListing.type,        
        reservePrice: listing.reservePrice,
        reserveNearlyMet: 0,
        reserveNotMet: 0,
        startsOn: startDateString,
        endsOn: endDateString,
        feeId: originalListing.fee.id,
        properties: [
            {name: 'lot', value: listing.specification.lot ?? ''},
        ]
    };

    log('updateListingDetails - Calling listingUpdate', listingUpdateRequest);
    await daxApi.listingUpdate(listing.listingId, listingUpdateRequest);
    log('updateListingDetails - listingUpdate success');
}

export const itemApprove = async (itemId: string) => {
    return daxApi.itemAccept(itemId);
}

export const itemReject = async (itemId: string) => {
    return daxApi.itemReject(itemId);
}

export const listingReject = async (listingId: string) => {
    return daxApi.listingReject(listingId);
}

export const listingWithdraw = async (listingId: string, reason: string) => {
    return daxApi.listingWithdraw(listingId, {reason});
}

interface ListingCreateResponse {
    id: string;
}

interface FeeResponse {
    id: string;
    productClassId: string;
    ccyCode: string;
    name: string;
    minValue: number;
    maxValue: number;
    rate: number;    
}

export const createDefaultListing = async (itemId: string) => {
    const fees = await daxApi.feeFindAll<FeeResponse[]>({includeInactive: false});

    if (!fees?.length) {
        throw new Error('Unable to create draft listing, no fees found for tenant');
    }

    const currentDate = new Date();
    const defaultStartDate = addYears(currentDate, 1);
    const defaultEndDate = addYears(currentDate, 2);
    const newListing: ListingCreateRequest = {
        itemId,
        name: nanoid(),
        feeId: fees[0].id,
        properties: [],
        startsOn: format(defaultStartDate, 'yyyy-MM-dd') + 'T00:00:00Z',
        endsOn: format(defaultEndDate, 'yyyy-MM-dd') + 'T00:00:00Z',
    };

    const listingResponse = await daxApi.listingCreate<ListingCreateResponse>(newListing);
    return listingResponse.id;
}

export const requestListingApproval = async (listingId: string) => {
    return daxApi.listingRequestApproval(listingId);
}

export const approveListingForSeller = async (listingId: string) => {
    return daxApi.listingSellerAccept(listingId);
}

export const requestEditListingForSeller = async (listingId: string) => {
    return daxApi.listingSellerRequestEdit(listingId);
}

export const updateListingComingSoonl = async (listingId: string) => {
    return daxApi.listingComingSoon(listingId);
}

interface StatusCount {
    status: string;
    count: number;
}

export const getItemStatusCountsForAdmin = async (): Promise<ItemStatusCountsForAdmin> => {
    const cancelToken = nanoid();
    const counts = await daxApi.itemStatusCountFindAllForAdmin<StatusCount[]>(undefined, {
        cancelToken,
    });

    const result: ItemStatusCountsForAdmin = {
        Draft: 0,
        Proposal: 0,
        Accepted: 0,
        Rejected: 0,
        Listed: 0,
    };

    counts.forEach(count => {
        switch (count.status) {
            case 'Draft': result.Draft = count.count; break;
            case 'Proposal': result.Proposal = count.count; break;
            case 'Accepted': result.Accepted = count.count; break;
            case 'Rejected': result.Rejected = count.count; break;
            case 'Listed': result.Listed = count.count; break;
        }
    });

    return result;
};

export const getListingStatusCountsForAdmin = async (): Promise<ListingStatusCountsForAdmin> => {
    const cancelToken = nanoid();
    const counts = await daxApi.listingStatusCountFindAllForAdmin<StatusCount[]>(undefined, {
        cancelToken,
    });

    const result: ListingStatusCountsForAdmin = {
        Draft: 0,
        ApprovalRequested: 0,
        Accepted: 0,
        Rejected: 0,
        ComingSoon: 0,
        Live: 0,
        Withdrawn: 0,
        EndedSold: 0,
        EndedNotSold: 0,
    };

    counts.forEach(count => {
        switch (count.status) {
            case 'Draft': result.Draft = count.count; break;
            case 'ApprovalRequested': result.ApprovalRequested = count.count; break;
            case 'Accepted': result.Accepted = count.count; break;
            case 'Rejected': result.Rejected = count.count; break;
            case 'ComingSoon': result.ComingSoon = count.count; break;
            case 'Live': result.Live = count.count; break;
            case 'Withdrawn': result.Withdrawn = count.count; break;
            case 'EndedSold': result.EndedSold = count.count; break;
            case 'EndedNotSold': result.EndedNotSold = count.count; break;
        }
    });

    return result;
};