import { Button, Stack, Typography } from "@mui/material";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import AttachFileIcon from '@mui/icons-material/AttachFile';
import styles from './WatchSummary.module.scss';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { InitDetail } from "lightgallery/lg-events";
import { LightGallery as LightGalleryPlugin } from "lightgallery/lightgallery";
import { LightGalleryLicense } from "../../../../constants";
import { WatchInfoBuyDetails } from "../../../models/WatchInfoBuyDetails";
import { AuthContext } from "../../../providers/AuthProvider";
import Parser from 'html-react-parser'
import NoPhotographyOutlinedIcon from '@mui/icons-material/NoPhotographyOutlined';
import { getDisplayDate } from "../../../../utils/date/dateUtils";
import EndTimer from "../../endTimer/EndTimer";
import { ActionItem, getActions } from "./WatchSummary.utils";
// import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

export interface WatchSummaryProps {
    watchDetails: WatchInfoBuyDetails;
    refreshListings: () => void;
    role?: string;
}

const WatchSummary = ({watchDetails, refreshListings, role}: WatchSummaryProps) => {
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const [showCondition, setShowCondition] = useState(true);
    const actions = useMemo(() => getActions(watchDetails, user, navigate), [navigate, user, watchDetails]);
    const lightGalleryRef = useRef<LightGalleryPlugin>();
    const lightGalleryConditionsRef = useRef<LightGalleryPlugin>();
    const allImages = useMemo(() => [
        ...(watchDetails.showroomImage ? [watchDetails.showroomImage] : []),
        ...watchDetails.images
    ], [watchDetails]);
    
    const onInit = useCallback((detail: InitDetail) => {
        lightGalleryRef.current = detail.instance;
    }, []);

    const onConditionsInit = useCallback((detail: InitDetail) => {
        lightGalleryConditionsRef.current = detail.instance;
    }, []);

    const handleGalleryShow = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        if (lightGalleryRef.current) {
            lightGalleryRef.current.openGallery();
        }
    }, []);

    const handleConditionShow = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        if (lightGalleryConditionsRef.current) {
            lightGalleryConditionsRef.current.openGallery();
        }
    }, []);

    const performAction = useCallback((action: ActionItem) => {
        return async () => {
            const shouldRefresh = await action.action();

            if(shouldRefresh) {
                refreshListings();
            }
        }
    }, [refreshListings]);

    const getActionButtons = useCallback((actions: ActionItem[]) => {
        return (
            <>
                {actions.map(action => (
                    <Button key={action.id} className={styles.actionButton} variant="contained" disableElevation onClick={performAction(action)}>
                        <Typography className={styles.actionText}>{action.name}</Typography>
                    </Button>
                ))}
            </>
        )
    }, [performAction]);

    return (
        <div className={styles.root}>
            <Stack direction='column' style={{gridColumn: 1}} className={styles.picturesContainer}>
                {allImages.length > 0 ? (
                    <>
                        <div className={styles.mainImageContainer}>
                            <a className={styles.imageOverlayLink} href='/' onClick={handleGalleryShow}>
                                <img alt="img1" src={allImages[0].thumbnailUrl} className={styles.mainImage} />
                                {/* <ZoomOutMapIcon className={styles.overlayIcon} /> */}
                            </a>
                        </div>
                        <div className={styles.otherImagesContainer}>
                            <LightGallery
                                licenseKey={LightGalleryLicense}
                                speed={500}
                                onInit={onInit}
                                plugins={[lgThumbnail, lgZoom]}
                                getCaptionFromTitleOrAlt={false}
                                showCloseIcon={true}
                            >
                                {allImages.map((image, index) => (
                                    <a key={`${watchDetails.id}_${image}`} href={image.mediaUrl}><img alt="img" src={image.thumbnailUrl} className={index === 0 ? styles.thumbnailImageFirst : styles.thumbnailImage} /></a>
                                ))}
                            </LightGallery>
                            {allImages.length > 3 && (
                                <a href='/' onClick={handleGalleryShow}>
                                    <div className={styles.moreContainer} >
                                        <Typography className={styles.moreText}>+{allImages.length - 3}</Typography>
                                    </div>
                                </a>
                            )}
                        </div>
                    </>
                ) : (
                    <Stack direction='column' className={styles.watchNoImageContainer}>
                        <NoPhotographyOutlinedIcon />
                        <Typography className={styles.watchNoImageText}>Image Unavailable</Typography>
                    </Stack>
                )}
            </Stack>
            <Stack direction='column' style={{gridColumn: 2}} className={styles.detailsContainer}>
                <Typography className={styles.brandText}>{watchDetails.brand}</Typography>
                <Typography className={styles.modelText}>{watchDetails.model}</Typography>
                <Typography className={styles.yearText}>{watchDetails.year}</Typography>
                {watchDetails.summary.split('||').map((line, index) => line === '' ? (
                    <div key={`${watchDetails.id}_summary_${index}`} className={styles.summarySpacer} />
                ) : (
                    <Typography key={`${watchDetails.id}_summary_${index}`} className={styles.summaryText}>
                        {Parser(line)}
                    </Typography>
                ))}
                <Stack direction='row' className={styles.specsContainer}>
                    <Stack direction='column'>
                        <Typography className={styles.specTitle} noWrap>Model Number:</Typography>
                        <Typography className={styles.specText}>{watchDetails.specification.modelNumber}</Typography>
                    </Stack>
                    <Stack direction='column' className={styles.specItemContainer}>
                        <Typography className={styles.specTitle} noWrap>Condition:</Typography>
                        <Typography className={styles.specText}>{watchDetails.specification.condition}</Typography>
                    </Stack>
                    <Stack direction='column' className={styles.specItemContainer}>
                        <Typography className={styles.specTitle} noWrap>Material:</Typography>
                        <Typography className={styles.specText}>{watchDetails.specification.material}</Typography>
                    </Stack>
                    <Stack direction='column' className={styles.specItemContainer}>
                        <Typography className={styles.specTitle} noWrap>Movement:</Typography>
                        <Typography className={styles.specText}>{watchDetails.specification.movement}</Typography>
                    </Stack>
                    <Stack direction='column' className={styles.specItemContainer}>
                        <Typography className={styles.specTitle} noWrap>Lot:</Typography>
                        <Typography className={styles.specText}>{watchDetails.specification.lot}</Typography>
                    </Stack>
                    <Stack direction='column' className={styles.specItemContainer}>
                        <Typography className={styles.specTitle} noWrap>Case:</Typography>
                        <Typography className={styles.specText}>{watchDetails.specification.case}</Typography>
                    </Stack>
                    <Stack direction='column' className={styles.specItemContainer}>
                        <Typography className={styles.specTitle} noWrap>Box:</Typography>
                        <Typography className={styles.specText}>{watchDetails.specification.box}</Typography>
                    </Stack>
                    <Stack direction='column' className={styles.specItemContainer}>
                        <Typography className={styles.specTitle} noWrap>Papers:</Typography>
                        <Typography className={styles.specText}>{watchDetails.specification.papers}</Typography>
                    </Stack>
                    <Stack direction='column' className={styles.specItemContainer}>
                        <Typography className={styles.specTitle} noWrap>Waterproof:</Typography>
                        <Typography className={styles.specText}>{watchDetails.specification.waterproof}</Typography>
                    </Stack>
                </Stack>
                <Stack direction='row'>
                    <div className={styles.conditionButton} onClick={e => setShowCondition(prev => !prev)}>
                        <Typography className={styles.conditionButtonText} noWrap>Condition</Typography>
                        {showCondition ?
                            <ArrowDropDownIcon className={styles.conditionButtonImage}/>
                        :
                            <ArrowDropUpIcon className={styles.conditionButtonImage}/>
                        }
                    </div>
                </Stack>
                {showCondition ?
                    watchDetails.conditionDetails.images.length > 0 || watchDetails.conditionDetails.description ? (
                        <Stack direction='column' className={styles.conditionContentContainer}>
                            {watchDetails.conditionDetails.images.length > 0 ? (
                                <div className={styles.otherImagesContainer}>
                                    <LightGallery
                                        licenseKey={LightGalleryLicense}
                                        speed={500}
                                        onInit={onConditionsInit}
                                        plugins={[lgThumbnail, lgZoom]}
                                        getCaptionFromTitleOrAlt={false}
                                        showCloseIcon={true}
                                    >
                                        {watchDetails.conditionDetails.images.map((image, index) => (
                                            <a key={`${watchDetails.id}_${image.mediaUrl}`} href={image.mediaUrl}><img alt="img" src={image.thumbnailUrl} className={index === 0 ? styles.thumbnailImageFirst : styles.thumbnailImage} /></a>
                                        ))}
                                    </LightGallery>
                                    {watchDetails.conditionDetails.images.length > 3 && (
                                        <a href='/' onClick={handleConditionShow}>
                                            <div className={styles.moreContainer} >
                                                <Typography className={styles.moreText}>+{watchDetails.conditionDetails.images.length - 3}</Typography>
                                            </div>
                                        </a>
                                    )}
                                </div>
                            ) : null}

                            {watchDetails.conditionDetails.description ? (
                                <Typography className={styles.conditionText}>{watchDetails.conditionDetails.description}</Typography>
                            ) : null}
                        </Stack>
                    ) : (
                        <Stack direction='row' className={styles.conditionContentContainer}>
                            <Typography className={styles.conditionNoInfo}>No additional condition information</Typography>
                        </Stack>
                    ) 
                : null}
            </Stack>

            <div style={{gridColumn: 3}} className={styles.divider}>
                <div className={styles.dividerItem} />
            </div>

            {watchDetails.status === 'ApprovalRequested' &&
                <div style={{gridColumn: 4}} className={styles.detailsRightContainer}>
                    <Stack direction='column' style={{gridRow: 1}} className={styles.statusContainer}>
                        <Typography className={styles.statusText} noWrap>STATUS</Typography>
                        <Typography className={styles.statusValueText} noWrap>{watchDetails.status}</Typography>
                    </Stack>
                    <Stack direction='column' style={{gridRow: 2}} className={styles.reserveTopContainer}>
                        <Typography className={styles.reserveText} noWrap>RESERVE</Typography>
                        <Typography className={styles.reserveValueText} noWrap>{watchDetails.reservePrice ? `£${watchDetails.reservePrice.toLocaleString()}` : 'No reserve'}</Typography>
                    </Stack>

                    <Stack direction='column' style={{gridRow: 4}} className={styles.reserveBottomContainer}>
                        <Typography className={styles.titleText} noWrap>CREATION DATE</Typography>
                        <Typography className={styles.valueText} noWrap>{getDisplayDate(watchDetails.createdOn)}</Typography>
                        <Typography className={styles.titleText} noWrap>PAYMENT TYPES ACCEPTED</Typography>
                        <Typography className={styles.valueText} noWrap>Stripe</Typography>

                        {getActionButtons(actions)}
                    </Stack>
                </div>
            }

            {['Accepted', 'ComingSoon'].includes(watchDetails.status) &&
                <div style={{gridColumn: 4}} className={styles.detailsRightContainer}>
                    <Stack direction='column' style={{gridRow: 1}} className={styles.statusContainer}>
                        <Typography className={styles.statusText} noWrap>STATUS</Typography>
                        <Typography className={styles.statusValueText} noWrap>{watchDetails.status}</Typography>
                    </Stack>
                    <Stack direction='column' style={{gridRow: 2}} className={styles.reserveTopContainer}>
                        <Typography className={styles.reserveText} noWrap>RESERVE</Typography>
                        <Typography className={styles.reserveValueText} noWrap>{watchDetails.reservePrice ? `£${watchDetails.reservePrice.toLocaleString()}` : 'No reserve'}</Typography>
                    </Stack>

                    <Stack direction='column' style={{gridRow: 4}} className={styles.reserveBottomContainer}>
                        <Typography className={styles.titleText} noWrap>DATE TO BE LISTED</Typography>
                        <Typography className={styles.valueText} noWrap>{getDisplayDate(watchDetails.auctionStartDate, watchDetails.auctionStartDateDescription)}</Typography>
                        <Typography className={styles.titleText} noWrap>PAYMENT TYPES ACCEPTED</Typography>
                        <Typography className={styles.valueText} noWrap>Stripe</Typography>

                        {getActionButtons(actions)}
                    </Stack>
                </div>
            }

            {watchDetails.status === 'Live' &&
                <div style={{gridColumn: 4}} className={styles.detailsRightContainer}>
                    <Stack direction='column' style={{gridRow: 1}} className={styles.statusContainer}>
                        <Typography className={styles.statusText} noWrap>STATUS</Typography>
                        <Typography className={styles.statusValueText} noWrap>{watchDetails.status}</Typography>
                    </Stack>
                    <Stack direction='column' style={{gridRow: 2}} className={styles.reserveTopContainer}>
                        <Typography className={styles.reserveText} noWrap>CURRENT BID</Typography>
                        <Typography className={styles.reserveValueText} noWrap>{watchDetails.currentBid ? `£${watchDetails.currentBid.toLocaleString()}` : 'No bids'}</Typography>
                    </Stack>

                    <Stack direction='column' style={{gridRow: 4}} className={styles.reserveBottomContainer}>
                        <Typography className={styles.titleText} noWrap>{ role !== 'buyer' ? 'RESERVE' : 'YOUR MAX BID' }</Typography>
                        { role !== 'buyer' ?
                            <Typography className={styles.valueText} noWrap>{watchDetails.reservePrice ? `£${watchDetails.reservePrice.toLocaleString()}` : 'No Reserve'}</Typography>
                            :
                            <Typography className={styles.valueText} noWrap>{watchDetails.userMaxBid ? `£${watchDetails.userMaxBid.toLocaleString()}` : 'No Bid'}</Typography>
                        }
                        <Typography className={styles.titleText} noWrap>DATE LISTED</Typography>
                        <Typography className={styles.valueText} noWrap>{getDisplayDate(watchDetails.auctionStartDate, watchDetails.auctionStartDateDescription)}</Typography>
                        <Typography className={styles.titleText} noWrap>PAYMENT TYPES ACCEPTED</Typography>
                        <Typography className={styles.valueText} noWrap>Stripe</Typography>
                        <Typography className={styles.titleText} noWrap>AUCTION ENDS</Typography>
                        <Stack direction='row'>
                            <EndTimer endDate={watchDetails.auctionEndDate} endDateDescription={watchDetails.auctionEndDateDescription} isLarge={false} onCountdownFinished={refreshListings} />
                        </Stack>
                        {/* <Stack direction='row' className={styles.contractContainer}>
                            <AttachFileIcon className={styles.contractIcon} />
                            <Typography className={styles.contractText} noWrap><a href='/'>contract.pdf</a></Typography>
                        </Stack> */}

                        {getActionButtons(actions)}
                    </Stack>
                </div>
            }

            {watchDetails.status === 'EndedNotSold' &&
                <div style={{gridColumn: 4}} className={styles.detailsRightContainer}>
                    <Stack direction='column' style={{gridRow: 1}} className={styles.statusContainer}>
                        <Typography className={styles.statusText} noWrap>STATUS</Typography>
                        <Typography className={styles.statusValueText} noWrap>{watchDetails.status}</Typography>
                    </Stack>
                    { role !== 'buyer' &&
                        <Stack direction='column' style={{gridRow: 2}} className={styles.reserveTopContainer}>
                            <Typography className={styles.reserveText} noWrap>RESERVE</Typography>
                            <Typography className={styles.reserveValueText} noWrap>{watchDetails.reservePrice ? `£${watchDetails.reservePrice.toLocaleString()}` : 'No reserve'}</Typography>
                        </Stack>
                    }
                    <Stack direction='column' style={{gridRow: 4}} className={styles.reserveBottomContainer}>
                        <Typography className={styles.titleText} noWrap>HIGHEST BID</Typography>
                        <Typography className={styles.valueText} noWrap>{watchDetails.currentBid ? `£${watchDetails.currentBid.toLocaleString()}` : 'No bids'}</Typography>
                        <Typography className={styles.titleText} noWrap>DATE TO BE LISTED</Typography>
                        <Typography className={styles.valueText} noWrap>{getDisplayDate(watchDetails.auctionStartDate, watchDetails.auctionStartDateDescription)}</Typography>
                        <Typography className={styles.titleText} noWrap>PAYMENT TYPES ACCEPTED</Typography>
                        <Typography className={styles.valueText} noWrap>Stripe</Typography>
                        <Typography className={styles.titleText} noWrap>AUCTION ENDED</Typography>
                        <Typography className={styles.valueText} noWrap>{getDisplayDate(watchDetails.auctionEndDate, watchDetails.auctionEndDateDescription)}</Typography>
                        
                        {getActionButtons(actions)}
                    </Stack>
                </div>
            }            

            {watchDetails.status === 'EndedSold' &&
                <div style={{gridColumn: 4}} className={styles.detailsRightContainer}>
                    <Stack direction='column' style={{gridRow: 1}} className={styles.statusContainer}>
                        <Typography className={styles.statusText} noWrap>STATUS</Typography>
                        <Typography className={styles.statusValueText} noWrap>{watchDetails.status}</Typography>
                    </Stack>
                    <Stack direction='column' style={{gridRow: 2}} className={styles.reserveTopContainer}>
                        <Typography className={styles.reserveText} noWrap>BOUGHT FOR</Typography>
                        <Typography className={styles.reserveValueText} noWrap>{watchDetails.winningBid ? `£${watchDetails.winningBid.bidAmount.toLocaleString()}` : 'Unknown'}</Typography>
                    </Stack>

                    <Stack direction='column' style={{gridRow: 4}} className={styles.reserveBottomContainer}>
                        <Typography className={styles.titleText} noWrap>AUCTION ENDED</Typography>
                        <Typography className={styles.valueText} noWrap>{getDisplayDate(watchDetails.auctionEndDate, watchDetails.auctionEndDateDescription)}</Typography>
                        <Typography className={styles.titleText} noWrap>PAYMENT TYPES ACCEPTED</Typography>
                        <Typography className={styles.valueText} noWrap>Stripe</Typography>
                        {/* <Stack direction='row' className={styles.contractContainer}>
                            <AttachFileIcon className={styles.contractIcon} />
                            <Typography className={styles.contractText} noWrap><a href='/'>contract.pdf</a></Typography>
                        </Stack> */}

                        {getActionButtons(actions)}
                    </Stack>
                </div>
            }

            {['Rejected', 'Withdrawn'].includes(watchDetails.status) &&
                <div style={{gridColumn: 4}} className={styles.detailsRightContainer}>
                    <Stack direction='column' style={{gridRow: 1}} className={styles.statusContainer}>
                        <Typography className={styles.statusText} noWrap>STATUS</Typography>
                        <Typography className={styles.statusValueText} noWrap>{watchDetails.status}</Typography>

                        {getActionButtons(actions)}
                    </Stack>
                </div>
            }

        </div>
    );
}

export default WatchSummary;